<template>
  <div>
    <!-- Header Alert -->
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b">
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Daftar <b>Paket Obat & Alkes</b> yang ada di Klinik Hayandra
      </div>
      <b-button
        squared
        variant="success"
        @click="$router.push('/medicine-packages/add')"
        v-if="btn">Tambah</b-button>
    </b-alert>

    <!-- Component List -->
    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <!-- Filter -->
            <div class="row justify-content-end mt-n3">
              <div class="col-md-3">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text squared>
                      <b-icon-search></b-icon-search>
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    type="text"
                    v-model="filter.name"
                    placeholder="Cari Nama Shortcut"
                    @keyup="filterByName"></b-form-input>
                </b-input-group>
              </div>
            </div>

            <!-- Table -->

            <div>
              <b-table
                striped
                hover
                class="mt-3"
                :items="items"
                :fields="fields"
                :style="'white-space: nowrap'"
                responsive="sm">
                <template #table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{ width: field.key === 'actions' ? '15%' : '' }" />
                </template>
                <template #cell(medicines)="data">
                  <!-- {{ displayAnesthetist(data.item.anesthetist_display) }} -->
                  <ul
                    v-for="medicines in data.item.display_medicine"
                    :key="medicines.id">
                    <li class="ml-5">
                      <span class="d-block">{{ medicines.display_name }}</span>
                      <span class="d-block">({{
                        medicines.quantity + " X " + medicines.sales_price
                      }})</span>
                      <!-- {{ medicines.name + ":" + medicines.quantity + `(${medicines.total_price})` }} -->
                    </li>
                  </ul>
                  <!-- <span v-if="data.item.anesthetist_display.length < 1">-</span> -->
                </template>
                <template #cell(actions)="data">
                  <b-button
                    size="sm"
                    class="mr-1 btn-info"
                    v-b-tooltip.hover
                    title="Detail"
                    @click="
                      $router.push({
                        path: '/medicine-packages/detail/' + data.item.id,
                      })
                    ">
                    <i class="fas fa-eye px-0"></i>
                  </b-button>
                  <b-button
                    size="sm"
                    class="mr-1 btn-success"
                    v-b-tooltip.hover
                    title="Ubah"
                    @click="
                      $router.push({
                        path: '/medicine-packages/edit/' + data.item.id,
                      })
                    "
                    v-if="btn">
                    <i class="fas fa-edit px-0"></i>
                  </b-button>
                  <b-button
                    size="sm"
                    class="btn-danger"
                    v-b-tooltip.hover
                    title="Hapus"
                    @click="deleteData(data.item.id)"
                    v-if="btn">
                    <i class="fas fa-trash px-0"></i>
                  </b-button>
                </template>
              </b-table>
              <b-pagination
                v-if="items.length != 0"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                @page-click="pageOnClick"
                class="mt-4"></b-pagination>
            </div>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>

import Card from "@/view/content/Card.vue";
import { debounce } from "@/core/modules/Helper.js"
import module from "@/core/modules/CrudModule.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "List",

  components: {
    Card,
  },

  data() {
    return {
      // Filter
      filter: {
        name: "",
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      // Table Head
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "package_name",
          label: "Nama Sortcut",
          sortable: true,
        },
        {
          key: "action_name",
          label: "Nama Tindakan",
          sortable: true,
        },
        {
          key: "medicines",
          label: "Obat dan Alkes",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      // Table Items
      items: [],
      // user access
      btn: false,
    };
  },

  methods: {
    async pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      await this.pagination();
    },

    filterByName() {
      debounce(() => { this.pagination() }, 500)
    },

    async pagination() {
      let filterParams = `&name=${this.filter.name}`;
      // let response = await module.paginate('medicine-packages', `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`)
      let response = await module.paginate(
        "medicine-packages",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("medicine-packages/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    setActiveButton() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);

      let a;
      for (a = 0; a < access_right.length; a++) {
        if (access_right[a] == "2702") {
          this.btn = true;
        }
      }
    },
  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Shortcut Obat dan Alkes" },
    ]);
    // Get Data
    this.pagination();
    this.setActiveButton();
  },
};
</script>
